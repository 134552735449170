@font-face {
  font-family: "Montreal Mono";
  src: url("../public/fonts/Montreal-Mono.otf");
}

@font-face {
  font-family: "Montreal Bold";
  src: url("../public/fonts/Montreal-Bold.ttf");
}

@font-face {
  font-family: "Montreal Regular";
  src: url("../public/fonts/Montreal-Regular.ttf");
}

.bg-biege-noise {
  background: url("../public/images/biege-noise.svg"), #d9d9d9;
  background-size: cover;
}

.bg-black-noise {
  background: url("../public/images/black-noise.svg"), #171713;
  background-size: cover;
  z-index: 1;
}

body {
  overscroll-behavior: none;
}

/* lenis */
html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.full-viewport-height {
  height: 100vh;
}

@supports (height: 100dvh) {
  .full-viewport-height {
    height: 100dvh;
  }
}

.footer-push {
  padding-bottom: clamp(1.6875rem, 1.6339rem + 0.2679vi, 1.875rem);
}
